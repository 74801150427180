
.SuggestionsContainer-module__allCarsResult {
    padding-bottom: 20px !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 16px;
}
.SuggestionsContainer-module__allCarsResult > * {
  margin-bottom: 16px;
  min-height: 100%;
  background-color: #ffffff;
}

.SuggestionsContainer-module__revampedHome {
  margin: 0 !important;
  margin-top: -20px !important;
  padding: 0 !important;
  border: none !important;
  width: 100% !important;
  max-width: 100% !important;
}

.SuggestionsContainer-module__revampedHome > h3,
.SuggestionsContainer-module__revampedTitle {
  color: #154f9c !important;
  font-weight: bold;
}

.SuggestionsContainer-module__allCarsResultRevamped {
  padding-bottom: 40px !important;
}

.SuggestionsContainer-module__showMorwBtn {
  height: 56px;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 16px;
  line-height: 24px;
}

.SuggestionsContainer-module__moreSameCar {
  margin: 0 auto;
  padding: 10px 15px;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
}

@media screen and (min-width: 1025px) {
  .SuggestionsContainer-module__showMorwBtn {
    width: 280px !important;
  }
  .SuggestionsContainer-module__SuggestionsContainerOnline {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .SuggestionsContainer-module__allCarsResult > * {
    width: 100%;
  }
  .SuggestionsContainer-module__revampedHome > h3,
  .SuggestionsContainer-module__revampedTitle {
    margin-top: 60px;
    margin-bottom: 10px;
  }
  .SuggestionsContainer-module__revampedTitle {
    font-size: 20px !important;
    line-height: 32px !important;
  }
  .SuggestionsContainer-module__revampedHome > .SuggestionsContainer-module__allCarsResult {
    margin-top: 0 !important;
    padding-bottom: 0px !important;
  }
  .SuggestionsContainer-module__showMorwBtn {
    width: 100% !important;
    height: 48px !important;
    background-image: url(https://cdn-frontend-r2.syarah.com/prod/static/media/arrShow.6311a428.svg);
    justify-content: flex-start;
    background-repeat: no-repeat;
  }
  body[dir="rtl"] .SuggestionsContainer-module__showMorwBtn {
    background-position: 15px center;
  }
  body[dir="ltr"] .SuggestionsContainer-module__showMorwBtn {
    background-position: calc(100% - 15px) center;
    background-image: url(https://cdn-frontend-r2.syarah.com/prod/static/media/arrShowEn.890789c1.svg);
    background-size: 18px;
  }
  .SuggestionsContainer-module__revampedHome > .SuggestionsContainer-module__allCarsResult > a:nth-child(n + 4) {
    display: none !important;
  }

  .SuggestionsContainer-module__postCard {
    flex-direction: row !important;
  }

  .SuggestionsContainer-module__postCard > div[class*="cardHead"] {
    width: 110px;
    min-height: 125px;
  }

  .SuggestionsContainer-module__postCard > div[class*="CardBody"] {
    width: calc(100% - 110px);
  }

  .SuggestionsContainer-module__postCard > div[class*="CardBody"] > h2 {
    color: #484848;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;

    width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .SuggestionsContainer-module__allCarsResult {
    width: 100%;
  }
}
@media (max-width: 768px) {
    .SuggestionsContainer-module__allCarsResult {
        display: block;
    }
}
